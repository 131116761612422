import React from "react"
import HeadingM from "../shared/HeadingM"
import Schedule from "../shared/program/Schedule"

const program = () => {
  const items = [
    {
      day: "月",
      contents:
        "プロご指導のバスケットボール",
    },
    {
      day: "水",
      contents:
        "cafe手伝い",
    },
    {
      day: "木",
      contents:
        "プロトレーナーによる体感トレーニング",
    },
    {
      day: "祝日",
      contents:
        "祝日はイベントとして活動しています。\n記念日に因んだ活動を取り入れています。",
    },
  ]
  return (
    <div>
      <HeadingM title="スケジュール" target="nico" />
      <Schedule items={items} target="nico" />
      ※開催曜日、内容が変更になることもあります。
    </div>
  )
}

export default program
